<template>
  <!--Uses tiptap version 2.0.3-->
  <div
    :class="{ tiptap_editor: true, 'tiptap_editor-invalid': invalid }"
    style="margin-top: 5px; margin-bottom: 5px"
  >
    <!-- <v-btn @click="focusInput">focus</v-btn> -->
    <!--Editor menu-->
    <div v-if="editor" class="tiptap_menubar">
      <v-btn
        @click="editor.chain().focus().undo().run()"
        :disabled="!editor.can().undo()"
        light
        color="black"
        text
        outlined
        small
        class="tiptap_menubutton"
      >
        <v-icon>$undo</v-icon>
      </v-btn>

      <v-btn
        @click="editor.chain().focus().redo().run()"
        :disabled="!editor.can().redo()"
        light
        color="black"
        text
        outlined
        small
        class="tiptap_menubutton"
      >
        <v-icon>$redo</v-icon>
      </v-btn>

      <v-divider vertical class="mx-2 tiptap-divider" light></v-divider>

      <v-btn
        @click="editor.chain().focus().toggleBold().run()"
        :class="{
          tiptap_menubutton: true,
          'tiptap_menubutton-active-blue': editor.isActive('bold'),
        }"
        light
        color="black"
        text
        outlined
        small
      >
        <v-icon>$bold</v-icon>
      </v-btn>

      <v-btn
        @click="editor.chain().focus().toggleItalic().run()"
        :class="{
          tiptap_menubutton: true,
          'tiptap_menubutton-active-blue': editor.isActive('italic'),
        }"
        light
        color="black"
        text
        outlined
        small
      >
        <v-icon>$italic</v-icon>
      </v-btn>

      <v-btn
        @click="editor.chain().focus().toggleUnderline().run()"
        :class="{
          tiptap_menubutton: true,
          'tiptap_menubutton-active-blue': editor.isActive('underline'),
        }"
        light
        color="black"
        text
        outlined
        small
      >
        <v-icon>$underline</v-icon>
      </v-btn>

      <v-divider vertical class="mx-2 tiptap-divider" light></v-divider>
      <v-btn
        @click="editor.chain().focus().setTextAlign('left').run()"
        :class="{ 'is-active': editor.isActive({ textAlign: 'left' }) }"
        light
        color="black"
        text
        outlined
        small
      >
        <img
          style="width: 23px; height: auto"
          src="../images/text-align-left-01.svg"
          alt="tbl icon"
        />
      </v-btn>
      <v-btn
        @click="editor.chain().focus().setTextAlign('center').run()"
        :class="{ 'is-active': editor.isActive({ textAlign: 'center' }) }"
        light
        color="black"
        text
        outlined
        small
      >
        <img
          style="width: 23px; height: auto"
          src="../images/text-align-middle-01.svg"
          alt="tbl icon"
        />
      </v-btn>
      <v-btn
        @click="editor.chain().focus().setTextAlign('right').run()"
        :class="{ 'is-active': editor.isActive({ textAlign: 'right' }) }"
        light
        color="black"
        text
        outlined
        small
      >
        <img
          style="width: 23px; height: auto"
          src="../images/text-align-right-01.svg"
          alt="tbl icon"
        />
      </v-btn>

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            style="font-size: 1rem; font-weight: 800"
            class="text-black"
            light
            color="black"
            text
            outlined
            small
            v-bind="attrs"
            v-on="on"
          >
            H1..6
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item
            @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
          >
            <v-list-item-title class="hdr-menu-item">H1</v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item
            @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
          >
            <v-list-item-title class="hdr-menu-item">H2</v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item
            @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
          >
            <v-list-item-title class="hdr-menu-item">H3</v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item
            @click="editor.chain().focus().toggleHeading({ level: 4 }).run()"
          >
            <v-list-item-title class="hdr-menu-item">H4</v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item
            @click="editor.chain().focus().toggleHeading({ level: 5 }).run()"
          >
            <v-list-item-title class="hdr-menu-item">H5</v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item
            @click="editor.chain().focus().toggleHeading({ level: 6 }).run()"
          >
            <v-list-item-title class="hdr-menu-item">H6</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-divider vertical class="mx-2 tiptap-divider" light></v-divider>
      <v-btn
        @click="editor.chain().focus().toggleBulletList().run()"
        :class="{
          tiptap_menubutton: true,
          'tiptap_menubutton-active-blue': editor.isActive('bulletList'),
        }"
        light
        color="black"
        text
        outlined
        small
      >
        <v-icon>$ul</v-icon>
      </v-btn>

      <v-btn
        @click="editor.chain().focus().toggleOrderedList().run()"
        :class="{
          tiptap_menubutton: true,
          'tiptap_menubutton-active-blue': editor.isActive('orderedList'),
        }"
        light
        color="black"
        text
        outlined
        small
      >
        <v-icon>$ol</v-icon>
      </v-btn>
      <v-divider vertical class="mx-2 tiptap-divider" light></v-divider>
      <v-btn
        title="Set Link"
        @click="setLink"
        light
        color="black"
        text
        outlined
        small
        :class="{ 'is-active': editor.isActive('link') }"
      >
        <img
          style="width: 22px; height: auto"
          src="../images/chain-link2.svg"
          alt="img icon"
        />
      </v-btn>
      <v-btn
        title="Unset Link"
        light
        color="black"
        text
        outlined
        small
        @click="editor.chain().focus().unsetLink().run()"
        :disabled="!editor.isActive('link')"
      >
        <img
          style="width: 22px; height: auto"
          src="../images/no-chain-link2-01.svg"
          alt="img icon"
        />
      </v-btn>
      <v-divider vertical class="mx-2 tiptap-divider" light></v-divider>
      <!-- <v-btn
        title="YouTube"
        light
        color="black"
        text
        outlined
        small
        @click="toggle_youtube_menu"
        :class="{ tt_menu_blue: show_youtube_menu }"
      >
        <img
          style="width: 26px; height: auto"
          src="../images/youtube2-01.svg"
          alt="img icon"
        />
      </v-btn> -->
      <v-divider vertical class="mx-2 tiptap-divider" light></v-divider>
      <v-btn
        light
        color="black"
        text
        outlined
        small
        @click="toggle_img_menu"
        :class="{ tt_menu_blue: show_img_menu }"
      >
        <img
          style="width: 26px; height: auto"
          src="../images/add-image-01.svg"
          alt="img icon"
        />
      </v-btn>
      <v-divider vertical class="mx-2 tiptap-divider" light></v-divider>
      <!-- <v-btn
        light
        color="black"
        text
        outlined
        small
        @click="toggle_tbl_menu"
        :class="{ tt_menu_blue: show_tbl_menu }"
      >
        <img
          style="width: 23px; height: auto"
          src="../images/table_Icon2.svg"
          alt="tbl icon"
        />
      </v-btn> -->
    </div>

    <!-- *********** table menu ************** -->
    <!-- <div v-if="editor && show_tbl_menu" class="tiptap_menubar table_menu">
      <hr style="width: 100%; height: 5px; border-top: 1px solid black" />
      <div class="d-flex align-center">
        <v-btn
          light
          color="black"
          text
          outlined
          small
          @click="
            editor
              .chain()
              .focus()
              .insertTable({
                rows: tbl_rows,
                cols: tbl_cols,
                withHeaderRow: true,
              })
              .run()
          "
        >
          Insert Table
        </v-btn>
        <label class="tt-menu-label">COLS:</label>
        <input
          class="tt-menu-input"
          type="text"
          v-model="tbl_cols"
          style="width: 23px"
        />
        <label class="tt-menu-label">ROWS:</label>
        <input
          class="tt-menu-input"
          type="text"
          v-model="tbl_rows"
          style="width: 23px"
        />
      </div>
      <v-divider vertical class="mx-2 tiptap-divider" light></v-divider>
      <v-btn
        @click="editor.chain().focus().goToPreviousCell().run()"
        light
        color="black"
        text
        outlined
        small
      >
        Prev Cell
      </v-btn>
      <v-btn
        @click="editor.chain().focus().goToNextCell().run()"
        light
        color="black"
        text
        outlined
        small
      >
        Next Cell
      </v-btn>

      <v-divider vertical class="mx-2 tiptap-divider" light></v-divider>
      <v-btn
        @click="editor.chain().focus().addColumnBefore().run()"
        light
        color="black"
        text
        outlined
        small
      >
        Add Col Before
      </v-btn>
      <v-btn
        @click="editor.chain().focus().addColumnAfter().run()"
        light
        color="black"
        text
        outlined
        small
      >
        Add Col After
      </v-btn>
      <v-btn
        @click="editor.chain().focus().deleteColumn().run()"
        light
        color="black"
        text
        outlined
        small
      >
        Del Col
      </v-btn>
      <v-btn
        @click="editor.chain().focus().addRowBefore().run()"
        light
        color="black"
        text
        outlined
        small
      >
        Add Row Before
      </v-btn>
      <v-btn
        @click="editor.chain().focus().addRowAfter().run()"
        light
        color="black"
        text
        outlined
        small
      >
        Add Row After
      </v-btn>
      <v-btn
        @click="editor.chain().focus().deleteRow().run()"
        light
        color="black"
        text
        outlined
        small
      >
        Del Row
      </v-btn>
      <v-divider vertical class="mx-2 tiptap-divider" light></v-divider>
      <div class="d-flex align-center">
        <v-btn
          @click="
            editor.chain().focus().setCellAttribute('colspan', col_span).run()
          "
          light
          color="black"
          text
          outlined
          small
        >
          Col Span
        </v-btn>
        <label class="tt-menu-label">COLSPAN:</label>
        <input
          class="tt-menu-input"
          type="text"
          v-model="col_span"
          style="width: 23px"
        />
      </div>
      <v-divider vertical class="mx-2 tiptap-divider" light></v-divider>
      <v-btn
        @click="editor.chain().focus().mergeCells().run()"
        light
        color="black"
        text
        outlined
        small
      >
        Merge
      </v-btn>
      <v-btn
        @click="editor.chain().focus().splitCell().run()"
        light
        color="black"
        text
        outlined
        small
      >
        Split
      </v-btn>
      <v-divider vertical class="mx-2 tiptap-divider" light></v-divider>
      <v-btn
        @click="editor.chain().focus().deleteTable().run()"
        light
        color="black"
        text
        outlined
        small
      >
        Del Table
      </v-btn>
    </div> -->
    <!-- *********** youtube menu ************** -->

    <!-- <div v-if="editor && show_youtube_menu" class="tiptap_menubar">
      <div class="d-flex justify-start">
        <v-text-field
          v-model="youtube.url"
          label="YouTube URL"
          class="ml-1"
          background-color="whitesmoke"
          solo
          hide-details
        ></v-text-field>
        <v-text-field
          v-model="youtube.width"
          label="Width"
          class="ml-1"
          background-color="whitesmoke"
          solo
          hide-details
          type="number"
        ></v-text-field>
        <v-text-field
          v-model="youtube.height"
          label="Height"
          class="ml-1"
          background-color="whitesmoke"
          solo
          hide-details
          type="number"
        ></v-text-field>
        <v-spacer></v-spacer>
      </div>
      <v-btn
        @click="addVideo"
        light
        class="white--text blue accent-3 ml-2"
        text
        outlined
      >
        Insert
      </v-btn>
    </div> -->
    <!-- *********** image menu ************** -->

    <div v-if="editor && show_img_menu" class="tiptap_menubar">
      <div class="d-flex justify-start">
        <v-select
          v-model="set_img_align"
          solo
          hide-details
          :items="img_align_items"
          label="Img Align"
          background-color="whitesmoke"
        ></v-select>
        <v-text-field
          v-model="set_img_width"
          label="Width %"
          class="ml-1"
          background-color="whitesmoke"
          solo
          hide-details
          type="number"
        ></v-text-field>
        <upload
          :lang="'eng'"
          :target="'img'"
          @uploadfile_images_done="uploadfile_images_done"
        ></upload>
        <v-spacer></v-spacer>
      </div>
    </div>
    <hr style="margin: 0" />
    <editor-content
      v-if="show_editor"
      :editor="editor"
      class="tiptap_editorbody"
      :id="uniqueID"
      spellcheck="false"
    />
    <!--<div v-if="editor" class="countertext">{{ editor.storage.characterCount.characters({ mode: 'nodeSize' }) }}</div>-->
  </div>
</template>

<style lang="scss">
a {
  color: rgb(0, 55, 255) !important;
  text-decoration: underline;
  font-weight: 600;
}
.ProseMirror:focus {
  outline: none;
}

.img_align_right {
  width: 10%;
  height: auto;
  margin-left: 90% !important;
}
.img_align_middle {
  margin: auto;
}
.img_align_left {
  float: left;
}
.tiptap_editorbody .ProseMirror img {
  padding: 5x;
  margin-left: 5px;
}
.tt_menu_blue {
  background-color: rgb(0, 221, 255);
}
.tt-menu-label {
  position: relative;
  top: 2px;
  font-weight: 800;
  margin-left: 6px;
  margin-right: 3px;
  font-size: 0.7rem;
}
.tt-menu-input {
  outline: 1px solid black;
  text-align: right;
  padding: 1px;
  height: 18px;
}
.tiptap_editorbody .ProseMirror table,
.tiptap_editorbody .ProseMirror td,
.tiptap_editorbody .ProseMirror tr,
.tiptap_editorbody .ProseMirror th {
  border: 1px solid black;
  border-collapse: collapse;
  min-width: 75px;
}
.tiptap_editorbody .ProseMirror td p {
  padding: 3px;
}
.tiptap_editorbody .ProseMirror table {
  margin: 5px;
}
.tiptap.ProseMirror.ProseMirror-focused {
  border: none;
}
.ti-host .tip .mb-0.text-item-text p {
  margin-bottom: 16px;
}
.ti-host .tip table,
.ti-host .tip td,
.ti-host .tip tr,
.ti-host .tip th {
  border: 1px solid black;
  min-width: 75px;
  min-height: 35px;
}
.ti-host .tip td p {
  min-height: 15px;
}
.ti-host .tip p {
  min-height: 15px;
}
.hdr-menu-item {
  font-size: 1rem;
  font-weight: 800;
  cursor: pointer;
}
.hdr-menu-item :hover {
  background-color: rgb(124, 123, 123);
  color: white !important;
}

.tiptap_editor {
  border: 0.5px solid #b6b6b6;
  background-color: white;
  border-radius: 4px;
  caret-color: black;
}

.tiptap_editor-invalid {
  border: 0.5px solid red;
}

.countertext {
  padding: 5px;
  font: small-caption;
  color: #000000a1;
}

/*Editor menu bar (buttons)*/
.tiptap_menubar {
  background-color: whitesmoke;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 3px;
  padding: 2px;
  width: 100%;
  row-gap: 5px;
  border-radius: 4px;
}

.tiptap_menubutton-active {
  color: var(--v-secondary-darken2) !important;
}
.tiptap_menubutton-active-blue {
  color: blue !important;
  font-weight: 800 !important;
}
.editor__menuicons {
  align-self: center;
  font-size: large;
}

/*Editor body*/
.tiptap_editorbody {
  color: black;
  padding: 5px;
  border: 3px solid var(--border-color);
  border-radius: 5px;
  width: 100%;
  max-height: 500px;
  overflow-y: scroll;
  min-height: 175px;
  resize: vertical;
  line-height: 1.2;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
}

/*Changes made stop counter css overriding*/
.tiptap_editor::v-deep .ProseMirror strong {
  font-size: inherit;
}

.tiptap_editor::v-deep .ProseMirror p {
  margin-bottom: 0.3rem;
  max-width: none !important;
}

.tiptap_editor::v-deep .ProseMirror:focus {
  outline: none;
}

.tiptap_editor::v-deep .ProseMirror h3 {
  margin-bottom: 0.3rem;
  max-width: none !important;
}

.tiptap_editor::v-deep .ProseMirror h2 {
  margin-bottom: 0.3rem;
  max-width: none !important;
}

.tiptap_editor::v-deep .ProseMirror h1 {
  margin-bottom: 0.3rem;
  max-width: none !important;
}
/*Changes end*/

@media only screen and (max-width: 461px) {
  .tiptap_menubutton {
    width: 20% !important;
  }

  .tiptap_editor {
    max-width: 100%;
    margin: inherit;
  }

  .tiptap-divider {
    display: none;
  }
}
</style>

<script>
import { Editor, EditorContent } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import CharacterCount from "@tiptap/extension-character-count";
import Document from "@tiptap/extension-document";
import Heading from "@tiptap/extension-heading";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";

import Gapcursor from "@tiptap/extension-gapcursor";

// import Table from "@tiptap/extension-table";
// import TableCell from "@tiptap/extension-table-cell";
// import TableHeader from "@tiptap/extension-table-header";
// import TableRow from "@tiptap/extension-table-row";

import Link from "@tiptap/extension-link";

import TextAlign from "@tiptap/extension-text-align";
import Image from "@tiptap/extension-image";
//import Youtube from "@tiptap/extension-youtube";
import upload from "./upload.vue";
import { bus_common } from "../mixins/bus_common";

export default {
  name: "tiptap",
  props: {
    characterLimit: Number,
    htmlLimit: Number,

    update_tiptap: Date,
  },

  components: {
    EditorContent,
    upload,
  },
  mixins: [bus_common],
  data() {
    return {
      uniqueID: "tiptap1",
      col_span: 2,
      tbl_cols: 5,
      tbl_rows: 5,
      show_youtube_menu: false,
      show_img_menu: false,
      show_tbl_menu: false,
      show_editor: true,
      editor: null,
      invalid: false,
      last_img_file: "",
      set_img_width: 30,
      set_img_align: "Left",
      img_align_items: ["Left", "Center", "Right"],
      // youtube: {
      //   url: "",
      //   width: 120,
      //   height: 120,
      // },
    };
  },

  watch: {
    value(value) {
      console.log("tiptap value: " + value);
      // HTML
      const isSame = this.editor.getHTML() === value;

      // JSON
      // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)

      if (isSame) {
        return;
      }

      this.editor.commands.setContent(value, false);
    },

    update_tiptap() {
      //this.editor.commands.clearContent();
      this.editor.destroy();
      setTimeout(() => {
        this.init();
      }, 200);
    },
  },
  computed: {},

  methods: {
    setLink() {
      const previousUrl = this.editor.getAttributes("link").href;
      const url = window.prompt("URL", previousUrl);

      // cancelled
      if (url === null) {
        return;
      }

      // empty
      if (url === "") {
        this.editor.chain().focus().extendMarkRange("link").unsetLink().run();

        return;
      }

      // update link
      this.editor
        .chain()
        .focus()
        .extendMarkRange("link")
        .setLink({ href: url })
        .run();
      return;
    },

    // toggle_youtube_menu() {
    //   this.show_youtube_menu = !this.show_youtube_menu;
    // },
    // addVideo() {
    //   const url = this.youtube.url;

    //   this.editor.commands.setYoutubeVideo({
    //     src: url,
    //     width: Math.max(320, parseInt(this.youtube.width, 10)) || 320,
    //     height: Math.max(180, parseInt(this.youtube.height, 10)) || 140,
    //   });
    //   this.youtube.url = "";
    //   this.youtube.width = 0;
    //   this.youtube.height = 0;
    //   this.show_youtube_menu = !this.show_youtube_menu;
    // },
    tip_img_style() {
      if (this.set_img_align == "Right") {
        let mlper = 100 - parseInt(this.set_img_width);
        let style_str =
          "width:" + this.set_img_width + "%; margin-left:" + mlper + "%;";
        console.log("style str: " + style_str);
        return style_str;
      }
      if (this.set_img_align == "Left") {
        // let mlper = 100 - parseInt(this.set_img_width);
        let style_str = "width:" + this.set_img_width + "%; margin-left:0%;";
        console.log("style str: " + style_str);
        return style_str;
      }
      if (this.set_img_align == "Center") {
        let mlper = 100 - parseInt(this.set_img_width);
        let style_str =
          "width:" +
          this.set_img_width +
          "%; margin-left:" +
          mlper / 2 +
          "%; margin-right:" +
          mlper / 2 +
          "%;";
        console.log("style str: " + style_str);
        return style_str;
      }
    },

    uploadfile_images_done(filename) {
      if (this.$store.state.mode == "dev") {
        this.editor
          .chain()
          .focus()
          .setImage({
            src: this.$store.state.dev_url_img + filename,
            alt: "altuser image",
            title: "title user image",
            style: this.tip_img_style(),
          })
          .run();
      } else {
        this.editor
          .chain()
          .focus()
          .setImage({
            src: this.$store.state.prod_url_img + filename,
            alt: "altuser image",
            title: "title user image",
            style: this.tip_img_style(),
          })
          .run();
      }
      let path = this.$store.state.dev_url_img;
      console.log("path: " + path);
      path = path.replaceAll("/");
      var img_elem = document.querySelector("img[src$='" + filename + "']");
      console.log("src back: " + img_elem.src);
      // img_elem.setAttribute("id", filename);
      // img_elem.classList.add("tipimg");
      this.last_img_file = filename;

      this.show_img_menu = false;
    },
    toggle_tbl_menu() {
      this.show_tbl_menu = !this.show_tbl_menu;
    },
    toggle_img_menu() {
      this.show_img_menu = !this.show_img_menu;
    },
    closeOnClick() {},
    init() {
      const element1 = document.getElementById(this.uniqueID);
      element1.addEventListener("click", this.handleBodyClick);

      //console.log("tiptap attr value: " + this.$attrs.value);

      this.editor = new Editor({
        content: this.$attrs.value,
        extensions: [
          StarterKit,
          Underline,
          CharacterCount.configure({
            limit: this.characterLimit,
            mode: "nodeSize",
          }),
          Document,
          Image.configure({
            HTMLAttributes: {
              inline: true,
              style: this.tip_img_style(),
            },
          }),
          Paragraph,
          Text,
          Heading.configure({
            levels: [1, 2, 3, 4, 5, 6],
          }),
          Gapcursor,
          // Table.configure({
          //   HTMLAttributes: {
          //     class: "table-class",
          //     style:
          //       "border:1px solid black;border-collapse:collapse;padding:3px;table-layout:fixed;width:100%;",
          //   },
          // }),
          // TableCell.configure({
          //   HTMLAttributes: {
          //     class: "table-cell",
          //     style:
          //       "border:1px solid black;border-collapse:collapse;padding:3px;word-wrap: break-word;",
          //   },
          // }),
          // TableHeader.configure({
          //   HTMLAttributes: {
          //     class: "table-head",
          //     style:
          //       "border:1px solid black;border-collapse:collapse;padding:3px;word-wrap: break-word;",
          //   },
          // }),
          // TableRow,
          TextAlign.configure({
            types: ["heading", "paragraph"],
          }),
          // Youtube.configure({
          //   inline: true,
          //   nocookie: true,
          //   disableKBcontrols: true,
          // }),
          Link.configure({
            HTMLAttributes: {
              class: "tiptap-link",
            },
            openOnClick: "whenNotEditable",
            linkOnPaste: false,
          }),
        ],

        onUpdate: () => {
          // HTML
          if (this.timeout) clearTimeout(this.timeout);

          this.timeout = setTimeout(() => {
            // TO DO: Potentially add a warning if the user exceeds a certain length of the HTML
            let html = this.editor.getHTML();
            this.$emit("input", html);
            //console.log("timeout tiptap: " + html);
          }, 500); // delay

          // JSON
          // this.$emit('update:modelValue', this.editor.getJSON())
        },
      });
    },
    updateData() {},
    focusInput() {
      this.editor.commands.focus();
    },
    handleBodyClick(event) {
      if (event.target.classList.contains("tiptap_editorbody")) {
        // Execute your function here
        this.focusInput();
      }
    },
  },

  beforeUnmount() {
    if (this.timeout) clearTimeout(this.timeout);
    this.editor.destroy();

    const element1 = document.getElementById(this.uniqueID);
    element1.removeEventListener("click", this.handleBodyClick);
    console.log("before editor unmount");
  },
  mounted() {
    this.init();
  },
};
</script>
