<template>
  <!--//RPD Missing Kids v0.1.1 
      All rights reseved.
      BCS grants Unlimited License to Rreno Police Department and City of Reno by contract.
      */-->
  <div id="upload" class="w100">
    <div class="w100">
      <!--<label v-text="lang == 'spn'? 'Archivo': 'Pick File' "></label>-->
      <!-- <input
          type="file"
          id="file"
          ref="file"
          v-on:change="handleFileUpload()"
        /> -->
      <v-file-input
        v-model="file"
        prepend-icon="$imagePlus"
        show-size
        accept="image/png, image/jpeg"
        label="Select File"
      ></v-file-input>
      <v-btn
        v-if="show_upload_btn"
        id="btnUploadFiles"
        v-on:click="submitFile()"
        class="white--text blue accent-3 ml-2"
        :disabled="!file"
        >Upload</v-btn
      >
      <!--<button v-on:click="load"></button>-->
    </div>

    <!--<hr v-if="lstFiles.length > 0" />
        <h4 v-if="lstFiles.length > 0" style="margin:0" v-text="lang == 'spn'? 'Los Archivos Guardados para el incidente': 'Files Saved Currently'"></h4>
        <div style="padding-bottom:20px;" v-if="lstFiles.length > 0">
          <div class="filename" v-for="fn in lstFiles">{{fn.filename|betterfn}}</div>
        </div>-->
  </div>
</template>

<style lang="scss" scoped>
@import "../scss/site.scss";
.w100 {
  width: 80%;
}

/* button stuff */
.edit-button,
.add-button,
.delete-button {
  width: 20%;
  height: 30px;
  padding: 0 5px 0 5px;
  margin: 0 4px 0 0;
  background-color: #7d6f80;
  font-size: 0.9rem;
}

.edit-button .editicon,
.add-button .editicon,
.delete-button .editicon {
  width: 100%;
  color: black;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  padding: 0;
}

.add-button:disabled:hover {
  background-color: #73bde6 !important;
}
</style>

<script>
import { bus_common } from "../mixins/bus_common";
export default {
  name: "upload",
  mixins: [bus_common],
  props: ["lang", "target"],
  data() {
    return {
      show_upload_btn: false,
      file: null,
      lstFiles: [],
      filetitle: "",
    };
  },
  computed: {
    user_email: function () {
      return this.$store.state.user.email;
    },
  },
  watch: {
    file: function () {
      if (this.file != null) {
        this.show_upload_btn = true;
      }
    },
  },
  methods: {
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    },

    submitFile() {
      this.show_loader(true);

      if (this.file == null) {
        if (this.lang == "eng") {
          this.swall_it(
            "Error Upload File",
            "No file Selected",
            "warning",
            null,
            null
          );
        }
        //if (this.lang == 'spn') {
        //  this.$swal({
        //    title: "Error Subir Archivo",
        //    text: 'No hay archivo elegido',
        //    type: "error",
        //    confirmButtonText: "OK"
        //  });
        //}
        this.show_loader(false);
        return true;
      }

      if (this.target == "img") {
        let formData = new FormData();
        formData.append("file", this.file);

        formData.append("email", this.user_email);

        //     this.$refs.file.value = null;
        this.show_loader(true);
        this.$store
          .dispatch("uploadfile_images", formData)
          .then((data) => {
            let fileName = data;
            this.$emit("uploadfile_images_done", fileName);
            this.file = null;
          })
          .catch((msg) => {
            this.show_loader(false);
            this.swall_it("Error Upload File", msg, "error", null, null);
          });
      }
    },
    load() {
      this.show_loader(true);
      this.show_loader(false);
    },
  },

  filters: {
    betterfn: function (name) {
      var nameSplt = name.toString().split("_");
      return nameSplt[1];
    },
  },
  mounted() {
    if (this.parent_lstFiles != null) {
      this.lstFiles = this.parent_lstFiles;
    }
  },
};
</script>
